.text-circle-container {
    max-width: 150px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.text-circle-circle {
    border-radius: 500px;
    position: relative;
    width: 100px;
    height: 100px;
}

.text-circle-children {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
}

.text-circle-text {
    text-align: center;
}
.text-circle-title {
    margin-top: 5px;
}
