body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100%;
    width: 100%;
    flex: 1 1;
}

.row {
    display: flex;
}
.column {
    display: flex;
    flex-direction: column;
}

.flex-item {
    flex: 1 1;
}

.justify-start {
    justify-content: flex-start;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: flex-end;
}

.align-start {
    align-items: flex-start;
}
.align-center {
    align-items: center;
}
.align-end {
    align-items: flex-end;
}

.margin-horizontal {
    margin-right: 20px;
    margin-left: 20px;
}
.margin-vertical {
    margin-top: 20px;
    margin-bottom: 20px;
}

.text-end {
    text-align: end;
}
.text-center {
    text-align: center;
}

.full-height{
    height: 100%;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.main-container {
    width: 100%;
    height: 100%;
    flex: 1 1;
    display: flex;
    /* overflow: hidden; */
}

.main-container-right {
    width: 100%;
    height: 100%;
    flex: 1 1;
    flex-shrink: 0;
}

.sidebar {
    width: 200px;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1 1;
    max-height: calc(100% - 60px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #f8f8f8;
}

.header {
    /* background-color: #123456; */
    min-height: 60px;
    height: 60px;
    display: flex;
    border-bottom: solid 1px rgb(235, 235, 235);
}

.sidebar-header {
    justify-content: center;
    align-items: center;
}
.sidebar-header img {
    height: 100%;
    width: auto;
}

.sidebar-menu {
    flex: 1 1;
    height: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    flex-shrink: 0;
    overflow-y: auto;
}

.header-tools {
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
}

.header-tools i {
    font-size: 20px;
}

.card-dashboard {
    display: flex;
    justify-content: space-around;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-item .anticon {
    font-size: 15px !important;
}

.ant-card {
    margin-bottom: 20px !important;
}

.login-form {
    max-width: 300px;
}
.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
}

.login-logo{
    max-width: 300px;
    width: 50%;
    height: auto;
}

.logout-button{
    font-size: 20px;
}
.logout-button:hover{
    cursor: pointer;
}

.text-circle-circle {
    border: 5px #006b39 solid;
}

.ant-menu-item-selected > a,
.ant-menu-item-selected > a i,
.ant-menu-item-selected,
.ant-dropdown-menu-item:hover {
    color: #fff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-dropdown-menu-item:hover,
.ant-spin-dot i,
.ant-badge-count,
.ant-calendar-selected-date .ant-calendar-date:hover,
.ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-selected-end-date .ant-calendar-date:hover,
.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date,
.ant-progress-success-bg,
.ant-progress-bg,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-btn-primary,
.ant-btn-primary:hover {
    background-color: #006b39;
}

.ant-progress-circle-path {
    stroke: #006b39 !important;
}

a:hover {
    color: #65b38e;
}

.ant-spin-dot i,
.ant-calendar-header a:hover,
a,
.logout-button:hover,
.antd-spin {
    color: #006b39;
}

/* .ant-btn:hover,
.ant-btn:focus {
    color: #006b39;
    border-color: #006b39;
} */
.ant-input:hover,
.ant-input-number:hover,
.ant-input:focus,
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled),
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-btn-primary{
    border-color: #006b39;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    background: #65b38e;
    border-color: #65b38e;
}
.ant-input:focus{
    box-shadow: 0 0 0 2px rgba(101, 179, 142, 0.2);
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #006b39;
    border-right-width: 1px !important;
}

.antd-spin{
    font-size: 30px;
}



.text-circle-container {
    max-width: 150px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.text-circle-circle {
    border-radius: 500px;
    position: relative;
    width: 100px;
    height: 100px;
}

.text-circle-children {
    position: absolute;
    top: 55%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 18px;
}

.text-circle-text {
    text-align: center;
}
.text-circle-title {
    margin-top: 5px;
}

