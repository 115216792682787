.text-circle-circle {
    border: 5px #006b39 solid;
}

.ant-menu-item-selected > a,
.ant-menu-item-selected > a i,
.ant-menu-item-selected,
.ant-dropdown-menu-item:hover {
    color: #fff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-dropdown-menu-item:hover,
.ant-spin-dot i,
.ant-badge-count,
.ant-calendar-selected-date .ant-calendar-date:hover,
.ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-selected-end-date .ant-calendar-date:hover,
.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date,
.ant-progress-success-bg,
.ant-progress-bg,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-btn-primary,
.ant-btn-primary:hover {
    background-color: #006b39;
}

.ant-progress-circle-path {
    stroke: #006b39 !important;
}

a:hover {
    color: #65b38e;
}

.ant-spin-dot i,
.ant-calendar-header a:hover,
a,
.logout-button:hover,
.antd-spin {
    color: #006b39;
}

/* .ant-btn:hover,
.ant-btn:focus {
    color: #006b39;
    border-color: #006b39;
} */
.ant-input:hover,
.ant-input-number:hover,
.ant-input:focus,
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled),
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-btn-primary{
    border-color: #006b39;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    background: #65b38e;
    border-color: #65b38e;
}
.ant-input:focus{
    box-shadow: 0 0 0 2px rgba(101, 179, 142, 0.2);
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #006b39;
    border-right-width: 1px !important;
}

.antd-spin{
    font-size: 30px;
}


