body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100%;
    width: 100%;
    flex: 1;
}

.row {
    display: flex;
}
.column {
    display: flex;
    flex-direction: column;
}

.flex-item {
    flex: 1;
}

.justify-start {
    justify-content: flex-start;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: flex-end;
}

.align-start {
    align-items: flex-start;
}
.align-center {
    align-items: center;
}
.align-end {
    align-items: flex-end;
}

.margin-horizontal {
    margin-right: 20px;
    margin-left: 20px;
}
.margin-vertical {
    margin-top: 20px;
    margin-bottom: 20px;
}

.text-end {
    text-align: end;
}
.text-center {
    text-align: center;
}

.full-height{
    height: 100%;
}