.main-container {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    /* overflow: hidden; */
}

.main-container-right {
    width: 100%;
    height: 100%;
    flex: 1;
    flex-shrink: 0;
}

.sidebar {
    width: 200px;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1;
    max-height: calc(100% - 60px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #f8f8f8;
}

.header {
    /* background-color: #123456; */
    min-height: 60px;
    height: 60px;
    display: flex;
    border-bottom: solid 1px rgb(235, 235, 235);
}

.sidebar-header {
    justify-content: center;
    align-items: center;
}
.sidebar-header img {
    height: 100%;
    width: auto;
}

.sidebar-menu {
    flex: 1;
    height: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    flex-shrink: 0;
    overflow-y: auto;
}

.header-tools {
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
}

.header-tools i {
    font-size: 20px;
}

.card-dashboard {
    display: flex;
    justify-content: space-around;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-item .anticon {
    font-size: 15px !important;
}

.ant-card {
    margin-bottom: 20px !important;
}

.login-form {
    max-width: 300px;
}
.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
}

.login-logo{
    max-width: 300px;
    width: 50%;
    height: auto;
}

.logout-button{
    font-size: 20px;
}
.logout-button:hover{
    cursor: pointer;
}
